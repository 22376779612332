import CardsSection from "../components/sections/CardsSection/CardsSection"
import CtaSection from "../components/sections/CtaSection"
import FaqAccordionSection from "../components/sections/FaqAccordionSection"
import GeneralHeader from "../components/global/GeneralHeader"
import Layout from "../components/global/Layout"
import QuoteSection from "../components/sections/QuoteSection/QuoteSection"
import React from "react"
import ValuePropsSection from "../components/sections/ValuePropsSection"

const Perks = ({ location }) => {

  const valueProps = [
    {
      headline:"Boost employee   satisfaction",
      subheadline:"Employees who feel appreciated are more likely to be productive, motivated and engaged—that’s the magic of meal perks.",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649356350/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Corporate%20Perks/EXPORT-CorporatePerks-ValueProp1.png",
    },
    {
      headline:"Create benefits parity for a hybrid work environment",
      subheadline:"Offering meal perks is an easy and cost-effective way to offer equal benefits to all employees, whether they’re working remotely or in the office.",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1652234863/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/Corporate%20Perks/EXPORT-CorporatePerks-ValueProp2.png",
    },
    {
      headline:"Deliver happiness beyond work hours",
      subheadline:"The magic of meal perks doesn’t have to stop at the end of the work day! With Grubhub+ Corporate, your employees can enjoy their favorite food after hours with unlimited free delivery and exclusive deals on orders.",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649356350/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Corporate%20Perks/EXPORT-CorporatePerks-ValueProp3.png",
    },
  ]

  const cards = [
    {
      title: "Digital Events",
      description: "Make your digital lunch and learns, webinars and client meetings more delicious with food!",
      link: "/meals-for-digital-events/",
      imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797392/Grubhub%20Corporate%20Website/2021/Photography%20Elements/_All%20Small%20Card%20Header%20Images/EXPORTS-CARD1-Images-DigitalEvents.jpg",
      linkText: "Learn more"
    },
    {
      title: "Remote Employees",
      description: "Show your remote team you care with customizable meal plans from Grubhub.",
      link: "/meals-for-remote-employees/",
      imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626796617/Grubhub%20Corporate%20Website/2021/Photography%20Elements/2-OrderingOccassion-Homepage/EXPORTS-Photography-CorpOccasionsHomepage-450x220-RemoteEmployees.png",
      linkText: "Learn more"
    },
    {
      title: "Hybrid Teams",
      description: "Keep your hungry teams happy, whether they're in the office, at home or both.",
      link:"/hybrid-teams/",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1661441639/Grubhub%20Corporate%20Website/Hybrid%20Teams%20Page/HybridTeams.jpg",
      linkText: "Learn more"
    }
  ]

  const faqs = [
    {
      header: "Does Grubhub for corporate have a rewards program?",
      description: "A Grubhub Corporate Account offers free perks for corporate diners, like free items and dollars off! "
    },
    {
      header: "Why do companies offer free lunch?",
      description: "<span>Many companies offer free lunch to retain current employees and attract new talent as meal programs boost staff morale. Additionally, free <a href='/blog/3-ideas-to-improve-your-team-lunch-program/'>team lunch</a> can help increase productivity by making sure your employees are fed and focused.</span>"
    }
  ]

  return (
    <Layout
      location={location}
      title={"Company Meal Perks | Corporate Rewards | Grubhub Corporate"}
      description={"Grubhub Perks provide your team with exclusive food delivery deals and rewards\u2014no strings attached! Your company doesn’t pay more for Perks, but your team gets more value."}
      className="perks-page"
      utag_data={{
        pageGroup: "grubhub - lead generation",
        subGroup: "ordering occasions",
        pageName: "corporate perks",
        brand: "grubhub"
      }}
      faqSeoList={faqs}
    >
      <GeneralHeader
        headline="Corporate Perks"
        subheadline="Discover how Grubhub Corporate can fit into your employee benefits package."
        textColor="var(--color-secondary)"
        background="rgba(254,232,163,0.5)"
        ctaLink="/get-started/"
        ctaText="Get started"
        type="half-image"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626796715/Grubhub%20Corporate%20Website/2021/Header%20Images/Subpage%20Headers/HEADERS-SUBPAGES-2560x1707-CorporatePerks.jpg"
        alt="Table filled with food containers such as shrimp tempura, edamame, rice and broccoli, sushi and noodles with two hands holding chopsticks reaching for the food."
      />
      <ValuePropsSection
        headline="Transform your workplace with meal benefits"
        valueProps={valueProps}
        layout="vertical"
      />
      {/* //TODO: edit author and position */}
      <QuoteSection
        quote='"Thank you Grubhub for having a variety of food options to keep our employees happy and well fed! Having a food perk makes us stand out as an employer."'
        author="Marge Josephson"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1627927171/Grubhub%20Corporate%20Website/2021/Partner%20Logos/PARTNERLOGOS-BayCityCapital.png"
        position="VP of HR and Operational Services, Bay City Capital LLC"
        backgroundColor="rgba(254,232,163,0.15)"
      />
      <CardsSection headline="More ordering occasions and information" cards={cards} chunkCards cardsPerRow={3} isSliderOnMobile/>
      <CtaSection headline="See what we can do for you" backgroundColor="#F9F9F9"/>
      <FaqAccordionSection faqs={faqs} backgroundColor="white"/>
    </Layout>
  )
}

export default Perks
